import request from '@/system/engine/request';
import config from '@/system/engine/config';

export function getLogin(postData){
	const data = request({
		url: config.url+'common/progress',
		method: 'post',
		data: postData
	});
	
	return Promise.resolve(data);
}

export function login(userInfo){
	const data = request({
		url: config.url+'common/progress/login',
		method: 'post',
		data: userInfo
	});
	
	return Promise.resolve(data);
}