<style>
	@import '../../assets/css/login.css';
</style>

<template>
	<div class="lock-screen">
		<div class="row content-inner" style="justify-content: center;">
			<div class="col-12 col-sm-12">
				<language id="language-select" class="float-right text-white mt-5 mr-5" style="font-size:20px;" ref="lang"/>
			</div>

			<div class="col-sm-12 p-0 text-center text-white">
				<b-col class="form-bg">
				<img src="../../assets/img/logo.png" class="mt-4" width="100px;" height="100px;">
				<p class="text-black m-0 mt-3">{{$t('general.title')}}</p>
				<el-form ref="loginForm" :model="loginForm" autocomplete="on" @keyup.enter.native="handleLogin" class="card-form">					
					<div id="login-area">
						<div class="input-container">
							<i class="fas fa-user"></i> 
							<input type="text" class="card-custom-input mt-4 ml-2" id="username" prefix-icon="el-icon-search" v-model="loginForm.username" :placeholder="$t('login.table_username')">
						</div>
						<div class="input-container">
							<i class="fas fa-key"></i> 
							<input :type="passwordType" class="card-custom-input mt-4 ml-2" id="pass" v-model="loginForm.password" :placeholder="$t('login.table_password')">
							<i class="el-icon-view" style="margin-right:-15px;color:#fff;" @click="showPassword"></i>
						</div>
					
						<button class="btn btn-primary btn-login-image mb-2" type="button" @click="handleLogin">{{$t('button.login')}}</button>

					
					</div>
				</el-form>
				</b-col>
			</div>
		</div>
	</div>
</template>

<script>
import {getLocalStorage, setLocalStorage} from '@/system/store/localstorage';
import {getLogin, login} from '@/system/model/common/login';
import {getDAES, getEAES} from '@/system/library/security';
import language from '@/components/language';

export default {
	components: {
		language,
	},data() {
		return {
			loginForm: {
				username: '',
				password: '',
			},
			passwordType: 'password',
			loading: false,
			errors: [],
			postData: {
				data: '',
				language: ''
			},
			submitForm:{
				action: 'login'
			}
		}
    },
    methods: {
		handleLogin() {
			this.loading = true;
			
			var checkValid = this.checkLogin();
			var text = '';
			var self = this;

			if(checkValid){
				this.postData.data = getEAES(JSON.stringify(this.loginForm));
				var result = login(this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));
					if(value.valid){
						setLocalStorage('job_id', data.job_id);
						setLocalStorage('auth_sign_token', data.token);
						setLocalStorage('permissionList', JSON.stringify(data.permission));
						self.$message({
							message: self.$t('msg.welcome',  {member: self.loginForm.username}),
							type: 'success'
						});
						self.$router.push('/dashboard');
						
					}else{
						self.errors = data.returnMsg;
						
						self.errors.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.popupErrorMessage(text);
						
						self.initial();
					}
				});
				
			}else{
				this.errors.forEach(function (value){
					text+= self.$t(value)+"<br/>";
				});
				this.popupErrorMessage(text);
			}
		},popupErrorMessage(text){
			this.$alert(text, this.$t('msg.msg_error_title'), {
				type: 'error',
				dangerouslyUseHTMLString: true
			});
			
			this.errors = [];
			this.loading = false;
		},checkLogin(){
			this.errors = [];
			
			if(!this.loginForm.username){
				this.errors.push('login.error_username_empty');
			}
			
			if(!this.loginForm.password){
				this.errors.push('login.error_password_empty');
			}
			
			return (this.errors.length > 0) ? false : true;
			
		}, initial(){
			this.postData.data = getEAES(JSON.stringify(this.submitForm));
			var result = getLogin(this.postData);
			var self = this;
			result.then(function(value){
				if(value.valid){
					var data = JSON.parse(getDAES(value.data));

					setLocalStorage('languageList', JSON.stringify(data.memberLanguageList));
					setLocalStorage('adminLanguageList', JSON.stringify(data.adminLanguageList));
					self.$refs.lang.update();
				}
			});
		},showPassword(){
			if(this.passwordType == 'password'){
				this.passwordType = 'text';
			}else{
				this.passwordType = 'password';
			}
		}
	}, created: function(){
	
		if(getLocalStorage('auth_sign_token')){
			this.$router.push('/dashboard');
		}else{
			var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
			this.$i18n.locale = currentLang;
			this.postData.language = currentLang;
			this.initial();
		}
	}
}
</script>

